import React from "react";
import BoxHeader from "../BoxHeader/BoxHeader";

const PageSubtitle = props => {
  const { title } = props;

  if (!title) {
    return null;
  }

  return (
      <BoxHeader headerType={'h1'} lineColor={'green'} contentAligned={false} style={{ marginBottom: '45px', textAlign: 'center' }}>{title}</BoxHeader>
  )
};

export default PageSubtitle;