import {graphql, useStaticQuery} from 'gatsby';
import React, {useState, useRef, useEffect} from "react";
import css from './main2.module.scss';
import {getImagesFluid, renderImageByFileName, viewports} from "../../../../componentsUtils";
import Video from "../../../../shared/Video/Video";
import Movie from "./Optiwaiplus_short_720.mp4";
import {useTranslation} from "react-i18next";
import GoToButton from "../../../../shared/GoToButton/GoToButton";
import {getLink, getRoutes} from "../../../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_home_main2"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Main2 = () => {
  const { t } = useTranslation();

  const imagesFluid = getImagesFluid(useStaticQuery(query));

  const mountedRef = useRef(true);

  useEffect(() => {
      return () => {
          mountedRef.current = false;
      }
  }, []);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const videoHiddenClass = showPlaceholder ? css.hidden : '';

  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        <h1>
          <div>Enhance your Images Automatically</div>
        </h1>
      </div>
      <div className={css.contentWrapper}>
        <div className={css.imageWrapper}>
          <div className={css.videoContent}>
              { showPlaceholder &&
                  <div className={css.placeholderImageWrapper}>
                      {renderImageByFileName(imagesFluid, 'Optiwaiplus_short_720.jpg', 'TODO placeholderImageAlt')}
                  </div>
              }
              <div className={[css.videoWrapper, videoHiddenClass].join(' ')}>
                  <Video
                      videoSrcURL={Movie}
                      onLoad={() => {
                          if (mountedRef.current) {
                              setShowPlaceholder(false)
                          }
                      }}
                  />
              </div>
          </div>
        </div>
        <div className={css.textWrapper}>
          <div className={css.text}>
              <div className={css.font400}><p>Edit your images quickly and at scale<br/></p></div>
              <div><p>Improve image quality</p><p>Improve image appeal</p><p>Enhance your images automagically</p></div>
          </div>
          <GoToButton
              sectionUrl={getLink([getRoutes().home, getRoutes().home.sections.getStarted])}
              text="Get started!"
          />
        </div>
      </div>
    </div>
  )
};

export default Main2;
