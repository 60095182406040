import {graphql, Link, useStaticQuery} from 'gatsby';
import React from "react";
import css from './homeNewsList.module.scss';
import Img from 'gatsby-image';
import NewsBasicTemplate from "../../../../shared/NewsBasicTemplate/NewsBasicTemplate";
import MainButton from "../../../../shared/MainButton/MainButton";
import {getLink, getRoutes} from "../../../../shared/routes";


/*
NOTE: If add field to frontmatter, add this field to content/news/template/index.md
Graphql with Gatsby needs at least one md file with fields getting here.
Template is used to copy and create any news.
 */
const query = graphql`
  query {
    allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            externalLink
            image {
              childImageSharp {
                fluid(maxWidth: 290, quality: 70) {
                  ...GatsbyImageSharpFluid,
                  originalName
                }
              }
            }
            imageDescription
            youtube
            copyright
          }
        }
      }
    }
    site {
      siteMetadata {
        newsMarkdownTemplateFile
      }
    }
  }
`;

const HomeNewsList = props => {
  const data = useStaticQuery(query);

  const { newsMarkdownTemplateFile } = data.site.siteMetadata;

  const posts = data.allMarkdownRemark.edges.filter(post => post.node.fields.slug !== newsMarkdownTemplateFile);

  const getDetailsLink = (externalLink, internalLink) => {
    if (!!externalLink) {
      return externalLink;
    }
    return internalLink;
  };

  const getImageMovieContent = frontmatter => {
    const content = { data: null, type: null };
    if (frontmatter.image) {
      const image = (frontmatter.image.childImageSharp && frontmatter.image.childImageSharp.fluid) || "";
      content.data = image && <Img fluid={image} alt={frontmatter.imageDescription} />;
      content.type = 'image';
    } else if (frontmatter.youtube) {
      const src = `https://www.youtube.com/embed/${frontmatter.youtube}`;
      content.data = (
        <iframe
          title={src}
          width="100%"
          src={`${src}?autoplay=1&mute=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
      content.type = 'youtube';
    }
    return content;
  };

  const mapPosts = () => {
    return posts.map((post, index) => {
      const { node = {} } = post;
      const { frontmatter = {}, fields = {} } = node;
      const slug = fields.slug;
      const {
        copyright = '',
        tags = '',
        externalLink = '',
        date = ''
      } = frontmatter;

      const title = node.frontmatter.title || slug;
      const newLink = getDetailsLink(externalLink, slug);
      const content = getImageMovieContent(frontmatter);
      const key = slug.replace(/\//g, '_').replace(/-/g, '_');

      return (
        <div key={key} id={key}>
          <NewsBasicTemplate
            external={!!externalLink}
            content={content.data}
            contentType={content.type}
            copyright={copyright}
            title={title}
            link={newLink}
            tags={tags}
            date={date}
          />
        </div>
      );
    })
  };

  const postsArray = mapPosts();

  const linkToAllNewsPage = (
    <div className={css.buttonWrapper}>
      <Link to={getLink([getRoutes().news])}>
        <MainButton label="See all" isLink={false} _tight filled color={'green'} />
      </Link>
    </div>
  );

  return (
    <div className={css.wrapper}>
      {/* <div className={css.header}>
        <BoxHeader lineColor={'green'}>
          News
        </BoxHeader>
        {linkToAllNewsPage}
      </div> */}
      <div className={css.contentWrapper}>
        {postsArray[0]}
        {postsArray[1]}
        {postsArray[2]}
      </div>
      {linkToAllNewsPage}
    </div>
  )
};

export default HomeNewsList;
