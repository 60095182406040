import React from "react";
import css from './computerVisionApplications.module.scss';
import OnlineSaleIconGreen from "../../../images/icons/online_sales_g.svg";
import RestorationIconGreen from "../../../images/icons/restoration_g.svg";
import SharingIconGreen from "../../../images/icons/sharing_g.svg";
import NavigationIcons from "../../shared/NavigationIcons/NavigationIcons";
import NavigationIconsItem from "../../shared/NavigationIcons/NavigationIconsItem";

const ComputerVisionApplications = () => {

  return (
    <div className={css.wrapper} >
        <NavigationIcons title="Computer Vision Applications">
            
        <div className={css.textWrapper}>
          <div className={css.text}>
                Create your own image workflows to handle high-volume image <br/>processing with our easy-to-use, drag-and-drop GUI interface.
            </div>
        </div>
            {/*<NavigationIconsItem
                image={OnlineSaleIconGreen}
                text="Online sales"}
                section={'home.onlineSales'}
            />
            <NavigationIconsItem
                image={SharingIconGreen}
                text="Sharing"}
                section={'home.sharing'}
            />
            <NavigationIconsItem
                image={RestorationIconGreen}
                text="Aged Photos"}
                section={'home.restoration'}
            />*/}
        </NavigationIcons>
    </div>
  )
};

export default ComputerVisionApplications;
