import React, {useState, useRef, useEffect} from "react";
import css from './slideTypeA.module.scss';
import {renderImageByFileName} from "../../componentsUtils";
import Video from "../Video/Video";
import PageSubtitle from "../PageTitle2/PageSubtitle";

const SlideTypeA = props => {
  const {
      title,
      videoSrcURL,
      placeholderImage,
      placeholderImageAlt = '',
      imagesFluid = [],
  } = props;

  const mountedRef = useRef(true);

  useEffect(() => {
      return () => {
          mountedRef.current = false;
      }
  }, []);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const videoHiddenClass = showPlaceholder ? css.hidden : '';

  return (
    <div className={css.wrapper}>
      { title && <PageSubtitle title={title} /> }
      <div className={css.content}>
          { showPlaceholder && placeholderImage &&
              <div className={css.placeholderImageWrapper}>
                  {renderImageByFileName(imagesFluid, placeholderImage, placeholderImageAlt)}
              </div>
          }
          <div className={[css.videoWrapper, videoHiddenClass].join(' ')}>
              <Video
                  videoSrcURL={videoSrcURL}
                  onLoad={() => {
                      if (mountedRef.current) {
                          setShowPlaceholder(false)
                      }
                  }}
              />
          </div>
      </div>
    </div>
  )
};

export default SlideTypeA;