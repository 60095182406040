import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './unlockThePowerContent.module.scss';
import PerformanceImageSvgPath from "../../../images/sections/unlockThePowerContentIcons/performance.svg";
import QualityImageSvgPath from "../../../images/sections/unlockThePowerContentIcons/quality.svg";
import SecurityImageSvgPath from "../../../images/sections/unlockThePowerContentIcons/security.svg";
import ReliabilitySvgPath from "../../../images/sections/unlockThePowerContentIcons/reliability.svg";
import ScalabilityImageSvgPath from "../../../images/sections/unlockThePowerContentIcons/scalability.svg";
import OptimiseCostsSvgPath from "../../../images/sections/unlockThePowerContentIcons/optimise_costs.svg";
import BoxHeader from "../../shared/BoxHeader/BoxHeader";



const UnlockThePowerContent = () => {
  const { t } = useTranslation();

  const renderSection = (index, title, imageAlt, text, image, extraContent) => {
    return (
      <div className={[css.section, css[`section${index}`]].join(' ')}>

        <div className={css.sectionImageWrapper}>
          <img src={image} alt={imageAlt} />

        </div>
        <div className={css.sectionTitle}>
            {title} 

        </div>
        <div className={css.sectionText}>
          <div className={css.sectionTextInner}>
            {text} 

          </div>
        </div>
        {extraContent}
      </div>
    );
  };

  /*const playVideoSection = (
    <div className={css.playVideo}>
      <MainButton
        href={`https://www.youtube.com/watch?v=70UyTQxnJA8`}
        label="Play video"
        filled
        className={css.playVideoButton}
      />
    </div>
  );*/
  const playVideoSection = null;

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <BoxHeader lineColor={'green'} contentAligned={false}>
            Benefits
        </BoxHeader>
       </div>
      <div className={css.wrapperInner}>
        {renderSection(1, "Performance", "optiwai performance", <><p>Worldwide availability</p><p>Geographic regions of infrastructure</p><p>Enterprise level architecture</p><p>Services diversification</p></>, PerformanceImageSvgPath)}
        {renderSection(2, "Quality",  "optiwai quality", <><p>Professional output quality</p><p>CMYK, RGB color spaces</p><p>Wide range of supported file types</p></>, QualityImageSvgPath, playVideoSection)}
        {renderSection(5, "Scalability", "optiwai scalability", <><p>Serverless Computing Platform</p><p>Microservices architecture</p><p>On-demand and scalable resources</p><p>Horizontally and vertically auto scaling</p><p>Auto metrics and reports</p></>, ScalabilityImageSvgPath)}
        {renderSection(4, "Reliability", "optiwai aws cloud saas", <><p>AWS Cloud infrastructure</p><p>High level of SaaS SLA standards</p><p>Auto upgrades and maintenance</p><p>Disaster recovery plan</p></>,  ReliabilitySvgPath)}
        {renderSection(3, "Security", "optiwai security", <><p>Identity and access management</p><p>File transfers encryption</p><p>Temporary files storage</p><p>API protection layers</p><p>Compliance solutions</p></>, SecurityImageSvgPath)}
        {renderSection(6, "Optimised costs","optiwai optimised cost roi",<><p>No initial costs</p><p>No infrastructure or staff costs</p><p>Fast integration</p><p>Excellent ROI</p></>,   OptimiseCostsSvgPath)}
      </div>
    </div>
  );
};

export default UnlockThePowerContent;
