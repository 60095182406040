import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';

import {getLink, getRoutes} from '../../shared/routes';
import CoveredVideo from "../../shared/CoveredVideo/CoveredVideo";
import {getImagesFluid} from "../../componentsUtils";
import OnlineSalesVideo from "../../../images/onlineSalesMovie/Online_sales.mp4";
import OnlineSaleIconBlack from "../../../images/icons/online_sales_b.svg";
import GoToButton from "../../shared/GoToButton/GoToButton";
import InspirationsSlideTitle from "../../shared/ImageSlider/InspirationsSlideTitle";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_onlineSalesMovie"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;



const HomeOnlineSalesMovie = () => {
  const imagesFluid = getImagesFluid(useStaticQuery(query));

  const title = (title) =>{
    return(
    <InspirationsSlideTitle title={title} />
    );
  };


  return (
      <CoveredVideo
        videoSrcURL={OnlineSalesVideo}
        placeholderImage={'Online_sales.jpg'}
        imagesFluid={imagesFluid}
        imagePosition={'right'}
        // icon={OnlineSaleIconBlack}
        // title={title(<>Increase sales with <br/> higher quality images</>)}
        text={<>{title(<>Increase sales with <br/> higher quality images</>)}Product photos are an important part of the buying process. Enhance your existing product images with just a few clicks.</>}
        buttons={<GoToButton pageUrl={getLink([getRoutes().UseCases])} />}
      />
  );
};

export default HomeOnlineSalesMovie;
