import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {getLink, getRoutes} from '../components/shared/routes';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './index.module.scss';
import Footer from '../components/shared/Footer/Footer';
import HomeNewsList from "../components/pages/home//partials/HomeNews/HomeNewsList";
import Main2 from "../components/pages/home/partials/main2/Main2";
import UnlockThePowerContent from "../components/sections/unlockThePowerContent/UnlockThePowerContent";
import ExpandMore from "../components/pages/home//partials/ExpandMore/ExpandMore";
import CoveredVideo from "../components/shared/CoveredVideo/CoveredVideo";
import {getImagesFluid} from "../components/componentsUtils";
import HomeOnlineSalesMovie from "../components/sections/homeOnlineSalesMovie/HomeOnlineSalesMovie";
import PredefinedWorkflowMovie from "../components//sections/PredefinedWorkflowMovie/PredefinedWorkflowMovie";
import RestorationMovie from "../components/pages/home//partials/movies/Restoration.mp4";
import WebPublishingMovie from "../components/pages/home//partials/movies/web-publishing.mp4";
import GoToYouTube from "../components/pages/home//partials/goToYouTube/GoToYouTube";
import ComputerVisionApplications from "../components/sections/ComputerVisionApplications/ComputerVisionApplications";
import GoToButton from "../components/shared/GoToButton/GoToButton";
import InspirationsSlideTitle from "../components/shared/ImageSlider/InspirationsSlideTitle";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_home_movies"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const HomePage = () => {

  const imagesFluid = getImagesFluid(useStaticQuery(query));

  const title = (title) =>{
    return(
    <InspirationsSlideTitle title={title} />
    );
  };

  return (
    <Layout >
      <SEO/>

      <div className={css.wrapper}>

        {/* <div className={css.Main}>
          <Main />
        </div> */}

        <div className={css.Main2}>
          <Main2 />
        </div>

        <div className={css.ExpandMore}>
          <ExpandMore />
        </div>

        {/* <div className={css.Images2} id={getRoutes().home.sections.getStarted}>
          <Images2/>
        </div> */}

        <div className={css.ComputerVisionApplications} id={getRoutes().home.sections.getStarted}>
          <ComputerVisionApplications />
        </div>

        <div className={css.OnlineSalesMovie} id={getRoutes().home.sections.onlineSales}>
          <HomeOnlineSalesMovie/>
        </div>

        <div className={css.WebPublishingMovie} id={getRoutes().home.sections.webPublishing}>
          <CoveredVideo
            videoSrcURL={WebPublishingMovie}
            placeholderImage={'WebPublishing.jpg'}
            imagesFluid={imagesFluid}
            // icon={WebPublishingIconBlack}
            // title="Ecommerce &amp; Web Publishing"
            text={<>{title(<>Ecommerce &amp; Web Publishing</>)}Improve the quality, clarity, and overall <br/>appeal of your online images.</>}
            buttons={<GoToButton pageUrl={getLink([getRoutes().UseCases])} />}
          />
        </div>

        <div className={css.RestorationMovie} id={getRoutes().home.sections.restoration}>
          <CoveredVideo
            videoSrcURL={RestorationMovie}
            placeholderImage={'Restoration.jpg'}
            imagesFluid={imagesFluid}
            // icon={RestorationIconBlack}
            title="Restore Aged Photos"
            text={<>Bring back the quality <br/> and luster of old photos.</>}
            buttons={<GoToButton pageUrl={getLink([getRoutes().UseCases])} />}
            imagePosition={'right'}
          />
        </div>

        <div className={css.PredefinedWorkflowMovie}>
          <PredefinedWorkflowMovie/>
        </div>

        <div className={css.GoToYouTube}>
          <GoToYouTube/>
        </div>

        <div className={css.HomeNews} id={getRoutes().home.sections.news}>
          <HomeNewsList />
        </div>

        <div className={css.UnlockThePowerContent} >
          <UnlockThePowerContent />
        </div>

        <div className={css.Footer}>
          <Footer />
        </div>

      </div>
    </Layout>
  );
};

export default HomePage;

