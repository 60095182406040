import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import css from './goToYouTube.module.scss';

import GoToButton from "../../../../shared/GoToButton/GoToButton";
import YouTubeIcon from "../../../../../images/icons/yt_w.svg";

const GoToYouTube = () => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialLinks {
              youtube
            }
          }
        }
      }
    `
  );

  const { site } = queryResult;
  const socialLinks = site.siteMetadata.socialLinks;

  return (
    <div className={css.wrapper}>
        <div className={css.content}>
            <div className={css.text}>
            See more videos on our Youtube channel
            </div>
            <GoToButton
                externalPageUrl={socialLinks.youtube}
                icon={YouTubeIcon}
                text="Go to YouTube"
            />
        </div>
    </div>
  )
};

export default GoToYouTube;
