import React from "react";
import css from './navigationIcons.module.scss';
import BoxHeader from "../../shared/BoxHeader/BoxHeader";

const NavigationIcons = props => {
  const { title = '', titleMargin = 24, children = null } = props;

  return (
    <div className={css.wrapper}>
        <div className={css.header}>
            <BoxHeader style={{marginBottom: titleMargin, textAlign: 'center' }} lineColor={'green'} contentAligned={false}>
                {title}
            </BoxHeader>
        </div>
        <div className={css.content}>
            {children}
        </div>
    </div>
  )
};

export default NavigationIcons;
