import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';

import {getImagesFluid} from "../../componentsUtils";
import PredefinedWorkflowVideo from "../../../images/predefinedWorkflowMovie/Pre-defined-customized-workflow.mp4";
import SlideTypeA from "../../shared/ImageSlider/SlideTypeA";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_predefinedWorkflowMovie"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const PredefinedWorkflowMovie = () => {
  const imagesFluid = getImagesFluid(useStaticQuery(query));

  return (
      <SlideTypeA
        videoSrcURL={PredefinedWorkflowVideo}
        placeholderImage={'Pre-defined-customized-workflow.jpg'}
        imagesFluid={imagesFluid}
        title="Pre-defined, customized workflow"
      />
  );
};

export default PredefinedWorkflowMovie;
